import "@fortawesome/fontawesome-free/css/all.min.css";
import { MDBDatatable } from "mdb-react-ui-kit";
import { MDBCard, MDBCardHeader, MDBCol, MDBContainer, MDBRow } from "mdbreact";
import React, { Suspense, useEffect, useRef, useState } from "react";
import {
  Button,
  ButtonGroup,
  Dropdown,
  DropdownButton,
  Modal,
  Nav,
  Tab,
} from "react-bootstrap";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useHistory } from "react-router";
import swal from "sweetalert";
import Swal from "sweetalert2";
import "../../../css/nodeCards.css";
import { Permissions } from "../../utils/constants";
import {
  checkUserLogin,
  handleError,
  lsDecrypt,
  permissionWarnings,
} from "../../utils/utils";
import NodeService from "../service/node.service";
import NodeGroupService from "../service/nodeGroup.service";
import TokenService from "../service/token.service";
import UserService from "../service/user.service";
import xssFilterService from "../service/xssfilter.service";
import ManageNodeCards from "./NodeCards/ManageNodeCards";
import NodeGroupList from "./NodeGroup/NodeGroupList";

const ManageNodes = () => {
  const [nodeBasicInfo, setNodeBasicInfo] = useState([]);
  const [nodeDisplayData, setNodeDisplayData] = useState([]);
  const [nodeLoadDone, setNodeLoadDone] = useState(false);
  const [open, setOpen] = useState(false);
  const [orgset, setOrgSet] = useState(new Set());
  const [subOrgset, setSubOrgSet] = useState(new Set());
  const [locationSet, setLocationSet] = useState(new Set());
  const [oseverSet, setOseverSet] = useState(new Set());
  const [fwverSet, setFWverSet] = useState(new Set());
  const [biosverSet, setBiosverSet] = useState(new Set());
  const [addNewGroupWindow, setAddNewGroupWindow] = useState(false);
  const [activeModalGroupWindow, setActiveModalGroupWindow] = useState(false);
  const [addNodeToGroupWindow, setAddNodeToGroupWindow] = useState(false);
  const [activeModalNodeToGroupWindow, setActiveModalNodeToGroupWindow] =
    useState(false);
  const [resultArrayData, setResultArrayData] = useState([]);
  const [actionData, setActionData] = useState(null);
  const [newNodeGroupName, setNewNodeGroupName] = useState("");
  const [newNodeGroupTag, setNewNodeGroupTag] = useState([]);
  const [nodeGroupListName, setNodeGroupListName] = useState([]);
  const [showOffCanvas, setShowOffCanvas] = useState(false);
  const history = useHistory();
  const firstUpdate = useRef(true);
  const [nodeGroupValue, setNodeGroupValue] = useState("");
  const [initialNodeGroupData, setInitialNodeGroupData] = useState([]);
  const [openNodeGroupList, setOpenNodeGroupList] = useState(false);
  const [sendDataToParent, setSendDataToParent] = useState("");
  const [addControlToUserGroup, setAddControlToUserGroup] = useState([]);
  const [addControlToUserGroupWindow, setAddControlToUserGroupWindow] =
    useState(false);
  const [userGroupCanManageNode, setUserGroupCanManageNode] = useState([]);
  const [userGroupValue, setUserGroupValue] = useState("");
  const [userGroupActionData, setUserGroupActionData] = useState([]);
  const [selectNodeList, setSelectNodeList] = useState([]);
  const [openSelectNodeGroup, setOpenSelectNodeGroup] = useState(false);
  const [notifyChildren, setNotifyChildren] = useState(0);
  const [originalSelectNodeGroup, setOriginalSelectNodeGroup] = useState([]);
  const [switchToCard, setSwitchToCard] = useState(true);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [mobileSelectNodeDropDownDis, setMobileSelectNodeDropDownDis] =
    useState(false);

  const [displayTitle, setDisplayTitle] = useState(
    "Manage Node and Node group"
  );

  const [mobileSelectNodeList, setMobileSelectNodeList] = useState([]);
  const [cardSelectNodeIDList, setCardSelectNodeIDList] = useState([]);
  const [dataLoad, setDataLoad] = useState(false);

  useEffect(() => {
    //handle initial rendering tasks
    var addNav = document.querySelector("#nav-display").classList;
    addNav.remove("nav-display");
    var addLayout = document.querySelector("#content-body").classList;
    addLayout.add("content-body");
    var addLayout2 = document.querySelector("#container-fluid").classList;
    addLayout2.add("container-fluid");

    //chcek user login, if login => check user permission
    if (checkUserLogin(history)) {
      //when load page, check user permission
      // if (
      //   JSON.parse(localStorage.getItem("userPermission")).permission.includes(
      //     Permissions.MANAGE_NODE_GROUPS
      //   )
      // )
      if (
        JSON.parse(lsDecrypt("userPermission")).permission.includes(
          Permissions.MANAGE_NODE_GROUPS
        )
      ) {
        handleGetAdminNodeList();
        setIsAdmin(true);
      } else {
        //if user has permission, => check the user's permission under the selection group, Besides the admin can manage the node, only the user has node relative permission can manage the node under
        // let criPermission = JSON.parse(
        //   localStorage.getItem("userPermission")
        // ).permission.includes(Permissions.MANAGE_NODES_CRITICAL_ACTION);

        // let norPermission = JSON.parse(
        //   localStorage.getItem("userPermission")
        // ).permission.includes(Permissions.MANAGE_NODES_NORMAL_ACTION);

        let criPermission = JSON.parse(
          lsDecrypt("userPermission")
        ).permission.includes(Permissions.MANAGE_NODES_CRITICAL_ACTION);
        console.log("dddddddddddddddddddddddddd");
        console.log(criPermission);

        let norPermission = JSON.parse(
          lsDecrypt("userPermission")
        ).permission.includes(Permissions.MANAGE_NODES_NORMAL_ACTION);

        if (criPermission || norPermission) {
          setIsAdmin(false);
          handleGetNodeByUserGroup();
        } else {
          //otherwise, user does not has permission => pop up warning
          permissionWarnings(history);
        }
      }
    }

    orgset.add("Org");
    subOrgset.add("Sub Org");
    locationSet.add("Location");
    oseverSet.add("Osever");
    fwverSet.add("FWver");
    biosverSet.add("BIOSver");

    setAddNodeToGroupWindow(false);
    setActiveModalNodeToGroupWindow(false);
    setActiveModalGroupWindow(false);
    setAddNewGroupWindow(false);
    setAddControlToUserGroupWindow(false);
    setAddControlToUserGroup(false);

    handleReloadNodeGroupList();

    window.addEventListener("resize", handleResize);
    if (window.innerWidth < 1380) {
      setIsSmallScreen(true);
    }
  }, []);
  //fetch a list of nodes managed by an admin.
  const handleGetAdminNodeList = () => {
    NodeService.getNodeList(TokenService.getToken())
      .then((response) => {
        //console.log("Get node list:");
        console.log(response);
        let node = JSON.stringify(response.data.nodes);
        setNodeBasicInfo(JSON.parse(node));
      })
      .catch((error) => {
        console.log(error.response);
        handleError(error, history, () => {
          handleGetAdminNodeList();
        });
      });
  };
  //get nodes based on user group.
  const handleGetNodeByUserGroup = () => {
    // Fetches the nodeGroups from localStorage and parses it from JSON.
    let nodeGroups = JSON.parse(lsDecrypt("nodeGroups"));
    //let nodeGroups = JSON.parse(localStorage.getItem("nodeGroups"));

    if (nodeGroups?.nodeGroup?.length === 0) {
      // Checks if the nodeGroup is empty, and if so, sets nodeBasicInfo to an empty array.
      setNodeBasicInfo([]);
    } else {
      // If not, it uses the NodeService to get nodes by user group.
      NodeService.getNodeByUserGroup(TokenService.getToken())
        .then((response) => {
          // console.log("Get node list:");
          console.log(response);
          let node = JSON.stringify(response.data.nodes);
          setNodeBasicInfo(JSON.parse(node));
        })
        .catch((error) => {
          console.log(error.response);
          handleError(error, history, () => {
            handleGetNodeByUserGroup();
          });
        });
    }
  };

  const handleResize = () => {
    if (window.innerWidth < 1380) {
      setIsSmallScreen(true);
    } else {
      setIsSmallScreen(false);
    }
  };

  useEffect(() => {
    // This function runs when the component mounts or when nodeBasicInfo changes
    // It updates the display data for the nodes based on the basic info
    setNodeDisplayData([]);
    setNodeLoadDone(false);
    console.log(
      "========================Set Node Basic Info================================"
    );

    if (nodeBasicInfo.length > 0) {
      const handleGetNodeArray = () => {
        NodeService.getNodeArray(TokenService.getToken(), nodeBasicInfo)
          .then((response) => {
            console.log(response);
            let nodeArray = response.data;

            for (let i = 0; i < nodeArray.length; i++) {
              // setNodeInfoData
              let fwver = "";
              let bios = "";
              let osver = "";
              let location = "";
              orgset.add(nodeArray[i].desc.org);
              subOrgset.add(nodeArray[i].desc.sub_org);

              if (nodeArray[i].desc.location === undefined) {
                locationSet.add(nodeArray.desc.loc);
                location = nodeArray[i].desc.loc;
              } else {
                locationSet.add(nodeArray[i].desc.location);
                location = nodeArray[i].desc.location;
              }

              let swInfoList = [];
              if (nodeArray[i].sw_info !== undefined) {
                console.log("############################");
                console.log(nodeArray[i].sw_info);
                console.log("############################");
                fwver = nodeArray[i].sw_info?.fwver;
                osver = nodeArray[i].sw_info?.osver;
                bios = nodeArray[i].sw_info?.bios;
                fwverSet.add(fwver);
                biosverSet.add(bios);
                oseverSet.add(osver);

                swInfoList = Object.entries(nodeArray[i].sw_info).map(
                  ([key, value]) => ({
                    component: key,
                    version: value,
                  })
                );
              }

              let obj = {
                id: nodeArray[i]._id,
                nodename: nodeArray[i].name,
                org: nodeArray[i].desc.org,
                suborg: nodeArray[i].desc.sub_org,
                // location: response.data.node.desc.location,
                location: location,
                fwver: fwver,
                bios: bios,
                osver: osver,
                ability: nodeArray[i].ability,
                swInfoList: swInfoList,
              };

              setNodeDisplayData((nodeDisplayData) => [
                ...nodeDisplayData,
                obj,
              ]);

              setNodeLoadDone(true);
            }
          })
          .catch((error) => {
            console.log(error.response);
            handleError(error, history, () => {
              handleGetNodeArray();
            });
          });
      };
      handleGetNodeArray();
    }
  }, [nodeBasicInfo]);

  useEffect(() => {
    if (nodeLoadDone) {
      if (nodeDisplayData.length === nodeBasicInfo.length) {
        console.log("oooooooooooooooooooooooooooooooooo");
        console.log(nodeDisplayData);
        console.log("oooooooooooooooooooooooooooooooooo");
        setOpen(true);
      }
    }
  }, [nodeDisplayData, nodeLoadDone]);

  //handle addition of a new node group.
  const handleAddNewNodeGroup = () => {
    const idsSet = new Set(cardSelectNodeIDList);
    // It filters nodeDisplayData based on the cardSelectNodeIDList and sets the result in resultArrayData.
    const filteredArray = nodeDisplayData.filter((node) => idsSet.has(node.id));
    // console.log(filteredArray);
    setResultArrayData(filteredArray);
    setActiveModalGroupWindow(true);
    setAddNewGroupWindow(true);
  };

  //close windows and resets data.
  const handleCloseAddNewNodeWindow = () => {
    setNewNodeGroupName("");
    setNewNodeGroupTag([]);
    setActiveModalGroupWindow(false);
    setAddNewGroupWindow(false);

    if (!switchToCard && isSmallScreen) {
      cancelSelectNode();
    }
  };

  const handleCloseAddNodeToGroupWindow = () => {
    setAddNodeToGroupWindow(false);
    setActiveModalNodeToGroupWindow(false);
    setNodeGroupListName([]);
    if (!switchToCard && isSmallScreen) {
      cancelSelectNode();
    }
  };

  // Function to handle deletion of a node.
  const handleDelete = (row) => {
    let tempArray = JSON.parse(JSON.stringify(resultArrayData));
    for (let i = 0; i < tempArray.length; i++) {
      if (tempArray[i].id === row.id) {
        tempArray.splice(i, 1);
        break;
      }
    }
    setResultArrayData(tempArray);
  };

  useEffect(() => {
    setActionData({
      columns: [
        { label: "", field: "order" },
        { label: "Node", field: "nodename" },
        { label: "Org", field: "org" },
        { label: "Sub Org", field: "suborg" },
        { label: "Location", field: "location" },

        { label: "Delete", field: "delete", sort: false },
      ],
      rows: resultArrayData.map((row, order) => {
        return {
          ...row,

          order: <div>{order + 1}</div>,
          delete: (
            <>
              <Button
                as="a"
                className="btn-node-edit nmc-btn-p"
                data-toggle="modal"
                data-target="#addOrg"
                onClick={() => handleDelete(row)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="#25AFA8"
                  className="bi bi-trash3"
                  viewBox="0 0 16 16"
                >
                  <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
                </svg>
              </Button>
            </>
          ),
        };
      }),
    });
  }, [resultArrayData]);

  // Function to handle a change in the name of the new node group.
  const handleChangeAddNewNodeGroupName = (e) => {
    let result = xssFilterService.removeHtmlTags(e);
    setNewNodeGroupName(result);
  };

  // Function to handle a change in the tags of the new node group.
  const handleChangeAddNewNodeGroupTags = (e) => {
    if (e !== "") {
      if (e.length < 2) {
        return;
      }
      let afterFilter = xssFilterService.removeHtmlTags(e);
      let result = afterFilter.match(/(".*?"|[^",\s]+)(?=\s*,|\s*$)/g);

      setNewNodeGroupTag(result);
    } else {
      setNewNodeGroupTag([]);
    }
  };

  const handleAddNodeToGroup = (e) => {
    //desktop node card
    if (!switchToCard) {
      setResultArrayData(mobileSelectNodeList);
    }

    const handleGetNodeList = () => {
      NodeGroupService.getNodeGroupList(TokenService.getToken())
        .then((response) => {
          console.log(response);

          if (!response.data.groups.length > 0) {
            swal(
              "Error!",
              "Add at least one group before adding node to group!",
              "error"
            );
          }
          if (response.status === 200) {
            let groupList = JSON.parse(JSON.stringify(response.data.groups));
            let tempArray = [];
            // loop over group list and extract group name and id
            for (let i = 0; i < groupList.length; i++) {
              tempArray.push({
                name: groupList[i].name,
                _id: groupList[i]._id,
              });
            }
            // set state with the new group list
            setNodeGroupListName(tempArray);
          }
        })
        .catch((error) => {
          console.log(error.response);
          handleError(error, history, () => {
            handleGetNodeList();
          });
        });
    };

    handleGetNodeList();

    const idsSet = new Set(cardSelectNodeIDList);
    const filteredArray = nodeDisplayData.filter((node) => idsSet.has(node.id));
    // console.log(filteredArray);
    setResultArrayData(filteredArray);
  };

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
    } else {
      // if there are node groups, set the first one as the default and open the modal
      if (nodeGroupListName.length > 0) {
        setNodeGroupValue(nodeGroupListName[0]._id);
        setAddNodeToGroupWindow(true);
        setActiveModalNodeToGroupWindow(true);
      }
    }
  }, [nodeGroupListName]);

  // handle submission of new node group
  const handleSubmitNewNodeGroup = () => {
    //check tag
    for (let i = 0; i < newNodeGroupTag.length; i++) {
      if (newNodeGroupTag.length > 1 && newNodeGroupTag[i].trim().length < 2) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Please Enter A Valid Group Tag!",
        });
        return;
      }
    }

    // if group name is invalid, show error and return
    if (newNodeGroupName.trim().length < 2) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please Enter A Valid Name!",
      });
      return;
      // if group name contains special characters, show error and return
    } else if (xssFilterService.containSpecialChar(newNodeGroupName)) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Name cannot contain special characters!",
      });
      return;
    } else {
      // function to handle creation of new node group
      const handleCreateNewNodeGroup = () => {
        NodeGroupService.createNewNodeGroup(
          TokenService.getToken(),
          newNodeGroupName,
          newNodeGroupTag
        )
          .then((response) => {
            console.log(response);
            if (response.status === 200) {
              NodeGroupService.getNodeGroupList(TokenService.getToken())
                .then((response) => {
                  if (response.status === 200) {
                    handleReloadNodeGroupList();
                    let groupid =
                      response.data.groups[response.data.groups.length - 1]._id;
                    //if create new Node Group, add those select node.
                    if (resultArrayData.length > 0) {
                      for (let i = 0; i < resultArrayData.length; i++) {
                        NodeGroupService.addNodeToGroup(
                          TokenService.getToken(),
                          groupid,
                          resultArrayData[i].id
                        )
                          .then((response) => {
                            handleAddNewNodeGroupSuccess();
                            // console.log(response);
                          })
                          .catch((error) => {
                            // console.log(error.response);
                            handleAddNewNodeGroupFail();
                            handleError(error, history);
                          });
                      }
                    } else {
                      //just create new Node Group, but not add node.
                      handleAddNewNodeGroupSuccess();
                    }
                  }
                })
                .catch((error) => {
                  console.log(error.response);
                  handleError(error, history);
                });
            }
            setNewNodeGroupName("");
            setNewNodeGroupTag([]);
          })
          .catch((error) => {
            console.log(error.response);
            handleError(error, history, () => {
              handleCreateNewNodeGroup();
            });
          });
        handleCloseAddNewNodeWindow();
      };
      handleCreateNewNodeGroup();
    }
  };

  const setNodeGroup = (e) => {
    setNodeGroupValue(e);
  };

  const handleAddNewNodeGroupSuccess = () => {
    swal("Success!", "Add New Node Group Success!", "success");
  };

  const handleAddNewNodeGroupFail = () => {
    swal("Error!", "Add New Node Group Fail", "error");
  };

  const handleAddNodeToGroupSuccess = () => {
    swal("Success!", "Add Node To Group Success!", "success");
  };

  const handleAddNodeToGroupFail = () => {
    swal("Error!", "Add Node To Group Fail", "error");
  };

  // Submits the addition of a node to the node group
  const handleSubmitAddNodeToNodeGroup = () => {
    for (let i = 0; i < resultArrayData.length; i++) {
      NodeGroupService.addNodeToGroup(
        TokenService.getToken(),
        nodeGroupValue,
        resultArrayData[i].id
      )
        .then((response) => {
          handleAddNodeToGroupSuccess();
        })
        .catch((error) => {
          // console.log(error.response);
          handleAddNodeToGroupFail();
          handleError(error, history, () => {
            handleSubmitAddNodeToNodeGroup();
          });
        });
    }
    handleCloseAddNodeToGroupWindow();
  };

  // Reloads the list of node groups
  const handleReloadNodeGroupList = () => {
    NodeGroupService.getNodeGroupList(TokenService.getToken())
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          let groupList = JSON.parse(JSON.stringify(response.data.groups));
          setInitialNodeGroupData(groupList);
        }
      })
      .catch((error) => {
        console.log(error.response);
        handleError(error, history, () => {
          handleReloadNodeGroupList();
        });
      });
  };

  useEffect(() => {
    if (sendDataToParent !== "") {
      // console.log(sendDataToParent);
      handleOpenNodeGroupList();
      setSendDataToParent("");
    }
  }, [initialNodeGroupData]);

  useEffect(() => {
    handleSetSelectNodeGroup();

    if (!openSelectNodeGroup && selectNodeList.length !== 0) {
      // console.log("selectNodeList");
      setOpenSelectNodeGroup(true);
      setNotifyChildren(notifyChildren + 1);
    }
  }, [selectNodeList]);

  useEffect(() => {
    if (userGroupCanManageNode.length !== 0) {
      setAddControlToUserGroupWindow(true);
      setAddControlToUserGroup(true);
      setOpenSelectNodeGroup(true);
      setUserGroup(userGroupCanManageNode[0].id);
    }
  }, [userGroupCanManageNode]);

  const handleCompleteSubmitEdit = (e) => {
    setSendDataToParent(e);
    handleCloseNodeGroupList();
    handleReloadNodeGroupList();
  };

  const handleOpenNodeGroupList = () => {
    setOpenNodeGroupList(true);
  };

  const handleCloseNodeGroupList = () => {
    setOpenNodeGroupList(false);
  };

  // Deletes selected node group
  const handleDeleteSelectNodeGroup = (e) => {
    setOpenSelectNodeGroup(false);
    setOriginalSelectNodeGroup(Array.from(selectNodeList));

    for (let i = 0; i < selectNodeList.length; i++) {
      // if the selected node's ID matches the given ID, remove it from the list
      let originalID = selectNodeList[i].id;
      let selectID = e.id;

      if (originalID === selectID) {
        selectNodeList.splice(i, 1);
        let result = Array.from(selectNodeList);
        setSelectNodeList(result);

        break;
      }
    }
  };

  const handleSetSelectNodeGroup = () => {
    setUserGroupActionData({
      columns: [
        { label: "", field: "order" },
        { label: "Node", field: "name" },
        { label: "Tags", field: "tags" },
        { label: "Delete", field: "delete", sort: false },
      ],
      rows: selectNodeList.map((row, order) => {
        return {
          ...row,

          order: <div>{order + 1}</div>,

          delete: (
            <>
              <Button
                as="a"
                className="btn-node-edit "
                data-toggle="modal"
                data-target="#addOrg"
                onClick={() => handleDeleteSelectNodeGroup(row)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="#25AFA8"
                  className="bi bi-trash"
                  viewBox="0 0 16 16"
                >
                  <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                  <path
                    fillRule="evenodd"
                    d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                  />
                </svg>
              </Button>
            </>
          ),
        };
      }),
    });
  };

  const handleAssignNodeGroupToUserGroup = () => {
    //console.log("Handle Assign Node Group To User Group Data");

    setUserGroupActionData({
      columns: [
        // { label: "ID", field: "id" },
        { label: "", field: "order" },
        { label: "Node", field: "name", width: 200 },
        { label: "Tags", field: "tags", width: 200 },

        { label: "Delete", field: "delete", sort: false },
      ],
      rows: selectNodeList.map((row, order) => {
        return {
          ...row,

          order: <div>{order + 1}</div>,

          delete: (
            <>
              <Button
                as="a"
                className="btn-node-edit nmc-btn-p"
                data-toggle="modal"
                data-target="#addOrg"
                onClick={() => handleDeleteSelectNodeGroup(row)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="#25AFA8"
                  className="bi bi-trash3"
                  viewBox="0 0 16 16"
                >
                  <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
                </svg>
              </Button>
            </>
          ),
        };
      }),
    });

    const handleGetAllUserGroup = () => {
      UserService.getAllUserGroup(TokenService.getToken())
        .then((response) => {
          console.log(response);

          if (response.status === 200) {
            let userGroup = new Set();
            let groupList = JSON.parse(JSON.stringify(response.data.groups));
            for (let i = 0; i < groupList.length; i++) {
              userGroup.add({
                name: groupList[i].name,
                id: groupList[i]._id,
              });
            }

            let temp = Array.from(userGroup);

            setUserGroupCanManageNode(temp);
          }
        })
        .catch((error) => {
          console.log(error.response);
          handleError(error, history, () => {
            handleGetAllUserGroup();
          });
        });
    };

    handleGetAllUserGroup();
  };

  const handleCloseAssignNodeGroupToUserGroup = () => {
    setUserGroupCanManageNode([]);
    setAddControlToUserGroupWindow(false);
    setAddControlToUserGroup(false);

    if (originalSelectNodeGroup.length !== 0) {
      setSelectNodeList(originalSelectNodeGroup);
    }
  };

  const handleSelectRow = (e) => {
    setSelectNodeList(e);
  };

  const setUserGroup = (e) => {
    setUserGroupValue(e);
  };

  //handle the assignment of a Node Group to a User Group
  const handleNodeGroupToUserGroup = () => {
    for (let i = 0; i < selectNodeList.length; i++) {
      UserService.addNodeGroupToUserGroup(
        TokenService.getToken(),
        userGroupValue,
        selectNodeList[i]._id
      )
        .then((response) => {
          console.log(response);
          handleAddNodeGroupToUserGroupSuccess();
          if (response.status === 200) {
            // console.log(response);
          }
        })
        .catch((error) => {
          // console.log(error.response);
          handleAddNodeGroupToUserGroupFail();
          handleError(error, history, () => {
            handleNodeGroupToUserGroup();
          });
        });
    }

    handleCloseAssignNodeGroupToUserGroup();
  };

  const handleAddNodeGroupToUserGroupSuccess = () => {
    swal("Success!", "Assign Control To User Group Success!", "success");
  };

  const handleAddNodeGroupToUserGroupFail = () => {
    swal("Error!", "Assign Control To User Group Fail", "error");
  };

  const handleOpenFilter = () => {
    setShowOffCanvas(true);
    if (showOffCanvas) {
      setShowOffCanvas(false);
    } else {
      setShowOffCanvas(true);
    }
  };

  // const handleSwitchToCard = () => {
  //   setSwitchToCard(false);
  //   console.log("card");
  // };

  // const handleSwitchToList = () => {
  //   setSwitchToCard(true);
  // };

  const handleMobileAddToExitGroup = () => {
    setMobileSelectNodeDropDownDis(true);
    setDisplayTitle("Add Node To Existing Group");
  };

  const handleMobileAddToNewGroup = () => {
    setMobileSelectNodeDropDownDis(true);
    setDisplayTitle("Add Node To New Group");
  };

  const handleMobileDeleteNode = () => {
    setMobileSelectNodeDropDownDis(true);
    setDisplayTitle("Delete Node");
  };

  const cancelSelectNode = () => {
    //console.log("cancel nodes!");
    setMobileSelectNodeDropDownDis(false);
    setDisplayTitle("Manage Node and Node group");
    setMobileSelectNodeList([]);
    setCardSelectNodeIDList([]);
    setResultArrayData([]);
  };

  const confirmSelectNode = () => {
    if (mobileSelectNodeList.length === 0) {
      //console.log("Please select at least one node!");
      Swal.fire({
        icon: "error",
        title: "Please select at least one node!",
      });
    } else {
      setMobileSelectNodeDropDownDis(false);

      if (displayTitle === "Add Node To Existing Group") {
        handleAddNodeToGroup();
      } else if (displayTitle === "Add Node To New Group") {
        handleAddNewNodeGroup();
      }

      setDisplayTitle("Manage Node and Node group");
    }
    //console.log(mobileSelectNodeList);
  };

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>Manage Nodes - NMC Web Monitor</title>
          <meta name="description" content="Manage Nodes" />
          <meta name="robots" content="noindex, nofollow" />
        </Helmet>
      </HelmetProvider>
      <div className="">
        <div className="d-flex align-items-center mb-3">
          <div className="mr-auto pr-3 mr-5  ">
            <h4 className="header-title  ml-2 manage-node-header">
              {displayTitle}
            </h4>
          </div>
        </div>
      </div>
      {isSmallScreen && (
        <div className="node-mobile-top-button">
          <Button
            as="a"
            className="more-filter-btn"
            onClick={() => handleOpenFilter()}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-filter mr-2"
              viewBox="0 0 16 16"
            >
              <path d="M6 10.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5z" />
            </svg>
            <div> {!isSmallScreen && <div>More</div>} Filter &nbsp;</div>
          </Button>

          <DropdownButton
            as={ButtonGroup}
            key={"mobile-node-add"}
            id={"mobile-node-add"}
            variant={"primary"}
            title={"Select Nodes "}
            disabled={mobileSelectNodeDropDownDis}
          >
            <Dropdown.Item eventKey="1" onClick={handleMobileAddToExitGroup}>
              Add To Existing Group
            </Dropdown.Item>
            <Dropdown.Item eventKey="2" onClick={handleMobileAddToNewGroup}>
              Add To New Group
            </Dropdown.Item>

            <Dropdown.Divider />
            {/* <Dropdown.Item
              eventKey="3"
              onClick={handleMobileDeleteNode}
              style={{ color: "#EE6164" }}
            >
              Delete Node
            </Dropdown.Item> */}
          </DropdownButton>
          {mobileSelectNodeDropDownDis && (
            <div className="mobile-xcheck">
              <Button
                to="/#"
                className="switch-Grid switch-padding-list list-node-switch"
                onClick={() => confirmSelectNode()}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-check-lg"
                  viewBox="0 0 16 16"
                >
                  <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z" />
                </svg>
              </Button>
              <Button
                to="/#"
                className="switch-Grid switch-padding-list list-node-switch cancel-select"
                onClick={() => cancelSelectNode()}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-x"
                  viewBox="0 0 16 16"
                >
                  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                </svg>
              </Button>
            </div>
          )}
        </div>
      )}
      <MDBContainer className=" user-list-container">
        <MDBRow className="">
          <MDBCol md="12">
            <MDBCard>
              <Tab.Container defaultActiveKey="groupname">
                <div className="manage-node-div">
                  <div className="card-header d-sm-flex d-block pb-0 border-0 card-header-tabtitle">
                    <div className="card-action  card-tabs2 node-card-tab">
                      <Nav
                        as="ul"
                        className="nav nav-tabs node-list-tab manage-node-tabs"
                        role="tablist"
                      >
                        <Nav.Item className="nav-item">
                          <Nav.Link
                            className="nav-item-tab"
                            data-toggle="tab"
                            eventKey="groupname"
                            onClick={(e) => handleCloseNodeGroupList(e)}
                          >
                            Node List ({nodeBasicInfo.length})
                          </Nav.Link>
                        </Nav.Item>
                        {isAdmin && (
                          <Nav.Item className="nav-item">
                            <Nav.Link
                              className="nav-item-tab"
                              eventKey="nodegrouplist"
                              onClick={(e) => handleOpenNodeGroupList(e)}
                            >
                              Node Group List({initialNodeGroupData.length})
                            </Nav.Link>
                          </Nav.Item>
                        )}
                      </Nav>
                      {/* {isSmallScreen && (
                        <div className="manage-node-switch mb-2">
                          <Button
                            to="/#"
                            className="switch-Grid switch-padding-list list-node-switch"
                            onClick={() => handleSwitchToList()}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              className="bi bi-list"
                              viewBox="0 0 16 16"
                            >
                              <path
                                fillRule="evenodd"
                                d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"
                              />
                            </svg>
                          </Button>

                          <Button
                            to="/#"
                            className="switch-Grid switch-padding-list list-node-switch"
                            onClick={() => handleSwitchToCard()}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              className="bi bi-grid-3x3-gap-fill"
                              viewBox="0 0 16 16"
                            >
                              <path d="M1 2a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V2zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1V2zM1 7a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V7zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V7zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1V7zM1 12a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1v-2zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1v-2zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1v-2z" />
                            </svg>
                          </Button>
                        </div>
                      )} */}
                    </div>

                    {!openNodeGroupList && !isSmallScreen && isAdmin && (
                      <>
                        <div className="manage-node-tab-side">
                          <Button
                            as="a"
                            to="/#"
                            className="btn btn-primary mb-1 mr-3 mt-2 ml-3"
                            onClick={() => handleAddNodeToGroup()}
                          >
                            <svg
                              width={25}
                              height={25}
                              viewBox="0 0 19 19"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              className="btn-icon"
                            >
                              <path
                                fillRule="evenodd"
                                d="M11 4a4 4 0 1 0 0 8 4 4 0 0 0 0-8zM6.025 7.5a5 5 0 1 1 0 1H4A1.5 1.5 0 0 1 2.5 10h-1A1.5 1.5 0 0 1 0 8.5v-1A1.5 1.5 0 0 1 1.5 6h1A1.5 1.5 0 0 1 4 7.5h2.025zM11 5a.5.5 0 0 1 .5.5v2h2a.5.5 0 0 1 0 1h-2v2a.5.5 0 0 1-1 0v-2h-2a.5.5 0 0 1 0-1h2v-2A.5.5 0 0 1 11 5zM1.5 7a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1z"
                                fill="#FFFFFF"
                              />
                            </svg>
                            Add Node To Existing Group
                          </Button>

                          <Button
                            as="a"
                            to="/manage-org"
                            className="btn btn-primary mb-1 mr-3 mt-2"
                            data-toggle="modal"
                            data-target="#addOrg"
                            onClick={(e) => handleAddNewNodeGroup(e)}
                          >
                            <svg
                              width={25}
                              height={25}
                              viewBox="0 0 19 19"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              className="btn-icon"
                            >
                              <path
                                fillRule="evenodd"
                                d="M6 3.5A1.5 1.5 0 0 1 7.5 2h1A1.5 1.5 0 0 1 10 3.5v1A1.5 1.5 0 0 1 8.5 6v1H11a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-1 0V8h-5v.5a.5.5 0 0 1-1 0v-1A.5.5 0 0 1 5 7h2.5V6A1.5 1.5 0 0 1 6 4.5v-1zM8.5 5a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1zM3 11.5A1.5 1.5 0 0 1 4.5 10h1A1.5 1.5 0 0 1 7 11.5v1A1.5 1.5 0 0 1 5.5 14h-1A1.5 1.5 0 0 1 3 12.5v-1zm1.5-.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1zm4.5.5a1.5 1.5 0 0 1 1.5-1.5h1a1.5 1.5 0 0 1 1.5 1.5v1a1.5 1.5 0 0 1-1.5 1.5h-1A1.5 1.5 0 0 1 9 12.5v-1zm1.5-.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1z"
                                fill="#FFFFFF"
                              />
                            </svg>
                            Add New Group
                          </Button>
                          {/* <Button
                            as="a"
                            to="/manage-org"
                            className="btn-deny  mb-1 mr-3 mt-2"
                            data-toggle="modal"
                            data-target="#addOrg"
                          >
                            <svg
                              width={25}
                              height={25}
                              viewBox="0 0 19 19"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              className="btn-icon"
                            >
                              <path
                                d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"
                                fill="#FFFFFF"
                              />
                              <path
                                fillRule="evenodd"
                                d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                                fill="#FFFFFF"
                              />
                            </svg>
                            Delete Node
                          </Button> */}
                        </div>
                      </>
                    )}
                    {openNodeGroupList && (
                      <>
                        <div className="manage-node-tab-side">
                          <Button
                            as="a"
                            to="/#"
                            className="btn btn-primary mb-1 mr-3 mt-2 ml-3"
                            onClick={() => handleAssignNodeGroupToUserGroup()}
                          >
                            <svg
                              width={25}
                              height={25}
                              viewBox="0 0 19 19"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              className="btn-icon"
                            >
                              <path
                                d="M6 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm4 8c0 1-1 1-1 1H1s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C9.516 10.68 8.289 10 6 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z"
                                fill="#FFFFFF"
                              />
                              <path
                                fillRule="evenodd"
                                d="M15.854 5.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 0 1 .708-.708L12.5 7.793l2.646-2.647a.5.5 0 0 1 .708 0z"
                                fill="#FFFFFF"
                              />
                            </svg>
                            Assign Control To User Group
                          </Button>
                        </div>
                      </>
                    )}
                  </div>

                  <div className="">
                    <Tab.Content className="tab-content">
                      <Tab.Pane role="tabpanel" eventKey="groupname">
                        {!dataLoad && <p className="">Loading...</p>}
                        {open && switchToCard && (
                          <Suspense fallback={<div>Loading...</div>}>
                            <ManageNodeCards
                              isReProvision={false}
                              setSwitchToCard={setSwitchToCard}
                              setShowOffCanvas={setShowOffCanvas}
                              showOffCanvas={showOffCanvas}
                              mobileSelectNode={mobileSelectNodeDropDownDis}
                              mobileSelectNodeList={[
                                mobileSelectNodeList,
                                setMobileSelectNodeList,
                              ]}
                              cardSelecNodeIDList={[
                                cardSelectNodeIDList,
                                setCardSelectNodeIDList,
                              ]}
                              setAllNodeList={null}
                              setSelectNodeName={null}
                              setReadyToReProvisionList={null}
                              isReadyToReProvision={false}
                              isAbandonNode={false}
                              setAbandonNodeList={null}
                              setDataLoad={setDataLoad}
                            />
                          </Suspense>
                        )}
                      </Tab.Pane>

                      {isAdmin && (
                        <Tab.Pane role="tabpanel" eventKey="nodegrouplist">
                          <div className="featured-menus owl-carousel ">
                            <div className="items ">
                              {openNodeGroupList && (
                                <NodeGroupList
                                  rows={initialNodeGroupData}
                                  handleCompleteSubmitEdit={
                                    handleCompleteSubmitEdit
                                  }
                                  handleSelectRow={handleSelectRow}
                                  setGroupListData={setInitialNodeGroupData}
                                />
                              )}
                            </div>
                          </div>
                        </Tab.Pane>
                      )}
                    </Tab.Content>
                  </div>
                </div>
              </Tab.Container>

              <MDBCardHeader
                tag="h3"
                className="text-center text-uppercase card-header-userlist"
              ></MDBCardHeader>
            </MDBCard>
          </MDBCol>
        </MDBRow>
      </MDBContainer>

      {addNewGroupWindow && (
        <Modal
          show={activeModalGroupWindow}
          onHide={handleCloseAddNewNodeWindow}
          className="fade"
          id="editOrg"
        >
          <div className="modal-content">
            <Modal.Header className="modal-header">
              <Modal.Title className="modal-title">
                Add New Node Group
              </Modal.Title>
              <Button
                variant=""
                onClick={() => handleCloseAddNewNodeWindow()}
                type="button"
                className="close"
              >
                <span>x</span>
              </Button>
            </Modal.Header>
            <Modal.Body className="user-modal-body">
              <form onSubmit={(e) => e.preventDefault()}>
                <div className="form-group">
                  <div className="new-nodegroup">
                    <div className="node-namebox mr-1">
                      <label className="normal-text mt-3 ml-1">
                        Node Group Name
                      </label>
                      <input
                        type="text"
                        className="form-control mb-3"
                        placeholder="Node Group Name"
                        maxLength="50"
                        minLength="2"
                        required
                        onChange={(e) =>
                          handleChangeAddNewNodeGroupName(
                            xssFilterService.removeHtmlTags(e.target.value)
                          )
                        }
                      />
                    </div>
                    <div className="node-namebox ml-1">
                      <label className="normal-text mt-3 ml-1">Tags</label>
                      <input
                        type="text"
                        className="form-control mb-3"
                        placeholder="Tag1 , Tag2"
                        maxLength="30"
                        onChange={(e) =>
                          handleChangeAddNewNodeGroupTags(
                            xssFilterService.removeHtmlTags(e.target.value)
                          )
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="mdbbox add-new-nodegroup">
                  <MDBDatatable
                    hover
                    data={actionData}
                    advancedData
                    entries={5}
                    entriesOptions={[5, 10, 15]}
                  />
                </div>
                <div className="nodegroup-submit">
                  {" "}
                  <input
                    className="btn btn-primary new-nodegroup-submit"
                    type="submit"
                    value="Submit"
                    onClick={() => handleSubmitNewNodeGroup()}
                  />
                </div>
              </form>
            </Modal.Body>
          </div>
        </Modal>
      )}

      {addNodeToGroupWindow && (
        <Modal
          show={activeModalNodeToGroupWindow}
          onHide={handleCloseAddNodeToGroupWindow}
          className="fade add-node-modal"
          id="editOrg"
        >
          <div className="modal-content">
            <Modal.Header className="modal-header">
              <Modal.Title className="modal-title ">
                Add Node To Existing Group
              </Modal.Title>
              <Button
                variant=""
                onClick={() => handleCloseAddNodeToGroupWindow()}
                type="button"
                className="close"
              >
                <span>x</span>
              </Button>
            </Modal.Header>
            <Modal.Body className="user-modal-body">
              <form onSubmit={(e) => e.preventDefault()}>
                <div className="form-group ">
                  <div className="new-nodegroup">
                    <div className="node-namebox mr-1">
                      <label className="normal-text ml-1">Node Group</label>
                      <select
                        className="form-control"
                        id="nodeGroupSelect"
                        value={nodeGroupValue}
                        onChange={(e) => setNodeGroup(e.currentTarget.value)}
                      >
                        {nodeGroupListName.map(({ name, _id }) => (
                          <option key={_id + name} value={_id}>
                            {name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="mdbbox add-nodeto-group">
                  <MDBDatatable
                    hover
                    data={actionData}
                    advancedData
                    entries={5}
                    entriesOptions={[5, 10, 15]}
                  />
                </div>
              </form>
            </Modal.Body>
            <div className="nodegroup-submit">
              <Button
                as="a"
                to="/#"
                className="btn btn-primary new-nodegroup-submit"
                onClick={() => handleSubmitAddNodeToNodeGroup()}
              >
                Submit
              </Button>
            </div>
          </div>
        </Modal>
      )}
      {addControlToUserGroup && (
        <Modal
          show={addControlToUserGroupWindow}
          onHide={handleCloseAssignNodeGroupToUserGroup}
          className="fade"
          id="editOrg"
        >
          <div className="modal-content">
            <Modal.Header className="modal-header">
              <Modal.Title className="modal-title">
                Assign Control To User Group
              </Modal.Title>
              <Button
                variant=""
                onClick={() => handleCloseAssignNodeGroupToUserGroup()}
                type="button"
                className="close"
              >
                <span>x</span>
              </Button>
            </Modal.Header>
            <Modal.Body className="user-modal-body">
              <form onSubmit={(e) => e.preventDefault()}>
                <div className="form-group">
                  <div className="new-nodegroup">
                    <div className="node-namebox mr-1">
                      <label className="normal-text ml-1">User Group</label>
                      <select
                        className="form-control"
                        id="sel1"
                        value={userGroupValue}
                        onChange={(e) => setUserGroup(e.currentTarget.value)}
                      >
                        {userGroupCanManageNode.map(({ name, id }) => (
                          <option key={id} value={id}>
                            {name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="mdbbox assign-contorl-userGroup">
                  {openSelectNodeGroup && (
                    <MDBDatatable
                      hover
                      data={userGroupActionData}
                      advancedData
                      entries={5}
                      entriesOptions={[5, 10, 15]}
                    />
                  )}
                </div>
              </form>
            </Modal.Body>
            <div className="nodegroup-submit">
              <Button
                as="a"
                to="/#"
                className="btn btn-primary new-nodegroup-submit"
                onClick={() => handleNodeGroupToUserGroup()}
              >
                Submit
              </Button>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default ManageNodes;
