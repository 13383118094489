import format from "date-fns/format";
import hexip from "hexip";
import NodeService from "../service/node.service";
import OtherService from "../service/other.service";
import ProvisionService from "../service/provision.service";
import TokenService from "../service/token.service";
var Provision_Header = "859100";

//Get provision data from API--
//create node provision
export async function createNodeProvision(name, org, subOrg, loc) {
  let desc = org + "," + subOrg + "," + loc;
  let result = "";

  await ProvisionService.createNodeProvision(
    TokenService.getToken(),
    name,
    desc
  )
    .then((response) => {
      console.log(response);
      if (response.data.message === "Success.") {
        console.log("Create Node Provision Success!");
        result = "Success.";
      }
    })
    .catch((error) => {
      console.log(error.response);
      result = error;
    });

  return result;
}

//get verifying Node List
export async function getVerifyingNodeList() {
  let result = [];

  await NodeService.getPendingNode(TokenService.getToken())
    .then((response) => {
      console.log(response);

      let verifyingNodes = JSON.parse(
        JSON.stringify(response.data.nodes.verifying)
      );
      result = verifyingNodes;
    })
    .catch((error) => {
      console.log(error.response);
      result = error;
    });

  return result;
}

//compare and find the node's id
export function getVerifyingNodeInfo(nodelist, deviceName) {
  let length = nodelist.length;
  let nodeID = "";

  for (let i = 0; i < length; i++) {
    if (nodelist[i].name === deviceName) {
      nodeID = nodelist[i]._id;
    }
  }
  return nodeID;
}

//get node provision by id
export async function getNodeProvisionInfo(id) {
  let result = [];
  await ProvisionService.getNodeProvision(TokenService.getToken(), id)
    .then((response) => {
      console.log(response);
      let nodeProvision = JSON.parse(JSON.stringify(response.data));
      result = nodeProvision;
    })
    .catch((error) => {
      console.log(error.response);
      result = error;
    });

  return result;
}

//get node provision list by id
export async function getNodeProvisionFileList(id) {
  let result = [];

  await ProvisionService.getProvisionFileList(TokenService.getToken(), id)
    .then((response) => {
      console.log(response);
      if (response.status === 200) {
        let fileList = JSON.parse(
          JSON.stringify(response.data.provision_files)
        );

        for (let i = 0; i < fileList.length; i++) {
          fileList[i].uploadTime = format(
            new Date(fileList[i].uploadTime),
            "MM/dd/yyyy HH:mm"
          );
        }

        result = fileList;
      } else {
        console.log(response);
      }
    })
    .catch((error) => {
      console.log(error.response);
      result = error;
    });

  return result;
}

export function printUserInput(
  deviceInfoArray,
  wifiSettingArray,
  serverInfoArray,
  proxySettingArray
) {
  console.log(deviceInfoArray);
  console.log(wifiSettingArray);
  console.log(serverInfoArray);
  console.log(proxySettingArray);
}

//Provision data helper---

const HexStrToIntArray = (HexStr) => {
  // "AABBCC" -> [170, 187, 204]
  var tmp = [];
  for (var index = 0; index < HexStr.length; index += 2) {
    tmp.push(parseInt(HexStr.substr(index, 2), 16));
  }
  return tmp;
};

const PortHexStrToIntArray = (HexStr) => {
  // "AABBCC" -> [170, 187, 204]
  var tmp = [];
  for (var index = 0; index < HexStr.length; index += 2) {
    tmp.unshift(parseInt(HexStr.substr(index, 2), 16));
  }
  return tmp;
};

const AppendData = (ary, data, cnt) => {
  for (var index = 0; index < cnt; index++) ary.push(data);
  return ary;
};

const StrToAsciiArray = (str) => {
  // "ABC" -> [65, 66, 67]
  var data = [];
  var tmp = str.split("");
  for (var index = 0; index < tmp.length; index++)
    data.push(CharToAscii(tmp[index]));
  return data;
};

const CharToAscii = (char) => {
  return char.charCodeAt(0);
};

const fillZeroArray = (initialArray, arraylength) => {
  return AppendData(initialArray, 0, arraylength - initialArray.length);
};

const handleHexIp = (ipstr) => {
  var tmp = [];
  for (var index = 0; index < ipstr.length; index += 2) {
    tmp.unshift(parseInt(ipstr.substr(index, 2), 16));
  }

  return tmp;
};

const handlePort = (port) => {
  let tempPortValue = (+port).toString(16).toUpperCase();
  let modePort = "0";
  if (tempPortValue.length % 2 !== 0) {
    modePort += tempPortValue;
  } else {
    modePort = tempPortValue;
  }

  // This guarantees the resulting array has at least two bytes.
  if (modePort.length < 4) {
    modePort = "00" + modePort;
  }

  return PortHexStrToIntArray(modePort);
};

//1.get provision head part (hex)
export function getProvisionHeadIntArray(setProvHead, provisionheader) {
  setProvHead(fillZeroArray(HexStrToIntArray(provisionheader), 16));
}

//2.get Node UID part (hex)
export function getNodeUIDArray(nodeUID, setNodeUIDArray) {
  setNodeUIDArray(fillZeroArray(HexStrToIntArray(nodeUID), 16));
}

//3.get Alias Name part (string)
export function getAliasNameArray(aliasName, setAliasNameArray) {
  setAliasNameArray(fillZeroArray(StrToAsciiArray(aliasName), 32));
}

//3_1. get Reprovision Alias Name part (String)
export function getReProAliasNameArray(setAliasNameArray) {
  setAliasNameArray(fillZeroArray(StrToAsciiArray(""), 32));
}

//4.get Description (string)
export function getDescriptionArray(org, subOrg, loc, setDescriptionArray) {
  let desc = org + "," + subOrg + "," + loc;
  setDescriptionArray(fillZeroArray(StrToAsciiArray(desc), 64));
}

//4_1.get reProvision Description (string)
export function getReProDescriptionArray(setDescriptionArray) {
  let desc = "";
  setDescriptionArray(fillZeroArray(StrToAsciiArray(desc), 64));
}

//5.get Node Secret Array(hex)
export function getNodeSecretArray(nodeWriteKey, setNodeWriteKeyArray) {
  setNodeWriteKeyArray(fillZeroArray(HexStrToIntArray(nodeWriteKey), 32));
}

//5_1.get reProvision Node Secret Array(hex)
export function getReProNodeSecretArray(setNodeWriteKeyArray) {
  setNodeWriteKeyArray(fillZeroArray(HexStrToIntArray(""), 32));
}

//6. get Server Secret Array(hex)
export function getServerSecretArray(serverWriteKey, setServerWriteKeyArray) {
  setServerWriteKeyArray(fillZeroArray(HexStrToIntArray(serverWriteKey), 32));
}

//6_1. get reProvision Server Secret Array(hex)
export function getReProServerSecretArray(setServerWriteKeyArray) {
  setServerWriteKeyArray(fillZeroArray(HexStrToIntArray(""), 32));
}

//7. Fill in 0 for the part reserved for node
// Node_RND_B64(32)+Server_RND_B64(32)+Node_PRVK(256)+Node_PUBK(256)+Server_PUBK(256)
export function getReservedArray(length, setReservedArray) {
  let temp = [];
  setReservedArray(fillZeroArray(temp, length));
}

//8. get WIFI SSID Array(string)
export function getWIFISSIDArray(wifiSSID, setWifiSSIDArray) {
  setWifiSSIDArray(fillZeroArray(StrToAsciiArray(wifiSSID), 32));
}

//9. get WIFI Password Array(string)
export function getWIFIPasswordArray(wifiPassword, setWifiPasswordArray) {
  setWifiPasswordArray(fillZeroArray(StrToAsciiArray(wifiPassword), 32));
}

//10. get Server IP Array(hex)
export function getServerIPArray(serverIP, setServerIPArray) {
  setServerIPArray(handleHexIp(hexip.hex(serverIP)));
}

//11. get Server Port (hex)
export function getServerPortArray(serverPort, setServerPortArray) {
  setServerPortArray(fillZeroArray(handlePort(serverPort)), 2);
}

//12. Reserved part after server information

//13.get Server Host Name (string)
export function getServerHostNameArray(serverHost, setServerHostArray) {
  setServerHostArray(fillZeroArray(StrToAsciiArray(serverHost), 64));
}

//14. get Proxy IP (hex)(
export function getProxyIPArray(proxyIP, setProxyIPArray) {
  setProxyIPArray(handleHexIp(hexip.hex(proxyIP)));
}

//15.get Proxy Port (hex)
export function getProxyPortArray(proxyPort, setProxyPortArray) {
  setProxyPortArray(fillZeroArray(handlePort(proxyPort), 2));
}

//16.Reserved part after proxy information

//17. get Proxy Login id (string)
export function getProxyLoginIDArray(proxyLoginID, setProxyLoginIDArray) {
  setProxyLoginIDArray(fillZeroArray(StrToAsciiArray(proxyLoginID), 32));
}

//18. get Proxy Login Password (string)
export function getProxyLoginPassword(
  proxyLoginPassword,
  setProxyLoginPasswordArray
) {
  setProxyLoginPasswordArray(
    fillZeroArray(StrToAsciiArray(proxyLoginPassword), 32)
  );
}

// 19 get ReProVision Nonce (hex)
export function getNonceIntArray(setNonce, nonce) {
  setNonce(fillZeroArray(HexStrToIntArray(nonce), 8));
}

// 20 get ReProVision Time (hex)
export function getExpiredDateIntArray(setExpiredDate, expiredDate) {
  setExpiredDate(fillZeroArray(HexStrToIntArray(expiredDate), 8));
}

// 21 get ReProVision sig (hex)
export function getSigIntArray(setSig, sig) {
  setSig(fillZeroArray(HexStrToIntArray(sig), 96));
}

export async function getIP(serverHostName, setServerIPValue, serverIPValue) {
  let result = "";
  await OtherService.ipLookUp(TokenService.getToken(), serverHostName)
    .then((response) => {
      console.log(response);
      if (response.data.ip !== "null" || response.data.ip !== "undefined") {
        setServerIPValue(response.data.ip);
      }
    })
    .catch((error) => {
      console.log(error);
      result = error;
    });
  return result;
}

//set ReProvision Parameters
export async function setNodeReProvision(id, mode, time) {
  let result = "";
  await ProvisionService.setReprovisionPara(
    TokenService.getToken(),
    id,
    mode,
    time
  )
    .then((response) => {
      console.log(response);
      result = "success";
    })
    .catch((error) => {
      console.log(error.response);
      result = error;
    });

  return result;
}

export function getReadyToReProvisionNode(nodelist) {
  let result = [];
  for (let i = 0; i < nodelist.length; i++) {
    if (nodelist[i].provision !== undefined) {
      result.push(nodelist[i]);
    }
  }
  return result;
}

export async function getAbandonNode() {
  let result = [];
  await NodeService.getAbandonNodeList(TokenService.getToken())
    .then((response) => {
      console.log(response);
      result = response.data.nodes;
    })
    .catch((error) => {
      console.log(error.response);
      result = error;
    });

  return result;
}

export async function signReProvision(id, data) {
  var bodyFormData = new FormData();
  bodyFormData.append("reprovision", data);
  let result = [];
  await ProvisionService.signReProvisionFile(
    TokenService.getToken(),
    id,
    bodyFormData
  )
    .then((response) => {
      console.log(response);
      result.push(response.data.nonce);
      result.push(response.data.sig);
      result.push(response.data.expired_date);
    })
    .catch((error) => {
      result = error;
    });

  return result;
}

export function getActionMap(action) {
  if (action === "modify") {
    return "Modify Network Configuration";
  }
  if (action === "clear_response") {
    return "Remove Machine";
  }
  if (action === "clear_immediate") {
    return "Force Remove Machine";
  }
}
