import $ from "jquery";
// Menu
import MetisMenu from "metismenujs";
import React, { Component } from "react";
import { Permissions } from "../../utils/constants";
import { lsDecrypt } from "../../utils/utils";
// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";
// Link
import { Link } from "react-router-dom";

// MetisMenu component
class MM extends Component {
  componentDidMount() {
    this.$el = this.el;
    this.mm = new MetisMenu(this.$el);
  }
  componentWillUnmount() {
    // Dispose of MetisMenu instance when component unmounts
    if (this.mm) {
      // this.mm("dispose");
      // this.mm.dispose();
    }
  }

  render() {
    return (
      <div className="mm-wrapper">
        <ul className="metismenu" ref={(el) => (this.el = el)}>
          {this.props.children}
        </ul>
      </div>
    );
  }
}

const handleClick = (props, path) => {
  if (props === path) {
    window.location.reload();
  }
  var aaa = document.querySelector("#main-wrapper");
  if ($(window).width() < 1200) {
    aaa.classList.toggle("menu-toggle");
  }
};

class SideBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      permissions: [],
      isRoot: false,
      isManager: false,
    };
    // Binding 'this' context to toggleFunc method
    this.toggleFunc = this.toggleFunc.bind(this);
  }

  // Function to toggle menu
  toggleFunc() {
    // console.log(this.props.location.pathname);
    var aaa = document.querySelector("#main-wrapper");
    return aaa.classList.toggle("menu-toggle");
  }

  // After the component mounts
  componentDidMount() {
    // console.log("Update SideBar");
    // sidebar open/close
    var btn = document.querySelector(".nav-control");
    //Change the side bar close in the beginning.
    var aaa = document.querySelector("#main-wrapper");
    aaa.classList.toggle("menu-toggle");
    const permissions = lsDecrypt("userPermission");

    // const permissions = localStorage.getItem("userPermission");
    const isRootValue = JSON.parse(localStorage.getItem("isRoot"));
    const isManager = JSON.parse(localStorage.getItem("isManager"));

    // Update the state with the values from localStorage
    if (permissions) {
      this.setState({
        permissions: JSON.parse(permissions).permission,
      });
    }

    this.toggleFunc();
    this.setState({ isRoot: isRootValue });
    this.setState({ isManager: isManager });
    // Attach click event listener
    btn.addEventListener("click", this.toggleFunc);
  }

  // After the component updates
  componentDidUpdate(prevProps) {
    if (
      prevProps.selectUserGroupPermissions[0] !==
      this.props.selectUserGroupPermissions[0]
    ) {
      // console.log(
      //   "userPermission changed:",
      //   this.props.selectUserGroupPermissions[0]
      // );

      this.setState((prevState) => {
        return {
          ...prevState,
          isRoot: this.props.selectUserGroupPermissions[0] === "Root",
        };
      });

      this.setState((prevState) => {
        return {
          ...prevState,
          permissions: this.props.selectUserGroupPermissions[0],
        };
      });
    }
  }

  componentWillUnmount() {
    // console.log(this.state);
    // Remove the event listener when the component unmounts
    var btn = document.querySelector(".nav-control");
    if (btn) {
      btn.removeEventListener("click", this.toggleFunc);
    }
  }

  render() {
    // Path
    const path = window.location.pathname;

    const { permissions } = this.state;
    const { isRoot } = this.state;
    const { isManager } = this.state;

    const manageUserGroups =
      permissions.length > 0
        ? permissions.includes(Permissions.MANAGE_USER_GROUPS)
        : false;

    const manageNodesGroup =
      permissions.length > 0
        ? permissions.includes(Permissions.MANAGE_NODE_GROUPS)
        : false;

    const FWupdate =
      permissions.length > 0
        ? permissions.includes(Permissions.FW_UPDATE)
        : false;

    const manageProvision =
      permissions.length > 0
        ? permissions.includes(Permissions.MANAGE_PROVISION)
        : false;

    const manageFWAndCert =
      permissions.length > 0
        ? permissions.includes(Permissions.MANAGE_FW_AND_CERTIFICATE)
        : false;

    const manageNodeCritical =
      permissions.length > 0
        ? permissions.includes(Permissions.MANAGE_NODES_CRITICAL_ACTION)
        : false;

    const manageNodeNormal =
      permissions.length > 0
        ? permissions.includes(Permissions.MANAGE_NODES_NORMAL_ACTION)
        : false;

    // Active menu
    let dashboards = ["dashboard"],
      nodestatus = ["node-status"],
      managenodes = [
        "manage-nodes",
        "nodes-joinpending",
        "manage-fw",
        "custom-actions",
      ],
      manageusergroup = ["manage-usergroup", "user-list"],
      manageorg = ["manage-org"],
      managetask = ["manage-task"],
      setprovision = ["setting-provision"],
      notificationsetting = ["notification-setting"];

    return (
      <div className="deznav side-barcolor">
        <div>{}</div>

        <PerfectScrollbar className="deznav-scroll">
          <MM className="metismenu" id="menu">
            <li
              id="dashboard-side-button"
              className={`${
                dashboards.includes(path.slice(1)) ? "mm-active" : ""
              }`}
            >
              <div className="sidebar-tag-main ">Main</div>
              <Link
                className="has-arrow ai-icon sidebar-navarrow"
                to="#"
                aria-expanded="false"
              >
                <i className="flaticon-381-speedometer"></i>
                <span className="nav-text sidebar-navtext">Dashboards</span>
              </Link>

              <ul aria-expanded="false">
                <li>
                  <Link
                    to="/dashboard"
                    onClick={() =>
                      handleClick(this.props.location.pathname, "/dashboard")
                    }
                  >
                    Dashboards
                  </Link>
                </li>
              </ul>
            </li>

            {!isRoot && (
              <li
                id="nodestatus-side-button"
                className={`${
                  nodestatus.includes(path.slice(1)) ? "mm-active " : ""
                }`}
              >
                <div className="sidebar-tag mt-1">Node</div>
                <Link
                  className="has-arrow ai-icon sidebar-navarrow"
                  to="#"
                  aria-expanded="false"
                >
                  <i className="flaticon-381-networking"></i>
                  <span className="nav-text sidebar-navtext">Node Status</span>
                </Link>
                <ul aria-expanded="false">
                  <li>
                    <Link
                      to="/node-status"
                      onClick={() =>
                        handleClick(
                          this.props.location.pathname,
                          "/node-status"
                        )
                      }
                    >
                      Node Status
                    </Link>
                  </li>
                  <li>
                    <ul aria-expanded="false"></ul>
                  </li>
                </ul>
              </li>
            )}
            {!isRoot &&
              (manageNodesGroup ||
                manageNodeCritical ||
                manageNodeNormal ||
                FWupdate ||
                manageFWAndCert) && (
                <li
                  id="managenodes-side-button"
                  className={`${
                    managenodes.includes(path.slice(1)) ? "mm-active " : ""
                  }`}
                >
                  <Link
                    className="has-arrow ai-icon sidebar-navarrow"
                    to="#"
                    aria-expanded="false"
                  >
                    <i className="flaticon-381-controls-3"></i>
                    <span className="nav-text sidebar-navtext">
                      Manage Nodes
                    </span>
                  </Link>

                  <ul aria-expanded="false" id="manage-nodes-id">
                    {(manageNodesGroup ||
                      manageNodeCritical ||
                      manageNodeNormal) && (
                      <li>
                        <Link
                          className=" "
                          to="/manage-nodes"
                          onClick={() =>
                            handleClick(
                              this.props.location.pathname,
                              "/manage-nodes"
                            )
                          }
                        >
                          Manage Nodes
                        </Link>
                      </li>
                    )}
                    {/* )} */}
                    {manageNodesGroup && (
                      <li>
                        <Link
                          className=" "
                          to="/nodes-joinpending"
                          onClick={() =>
                            handleClick(
                              this.props.location.pathname,
                              "/nodes-joinpending"
                            )
                          }
                        >
                          Node Join Pending
                        </Link>
                      </li>
                    )}

                    {(manageFWAndCert || FWupdate) && (
                      <li>
                        <Link
                          className=" "
                          to="/manage-fw"
                          onClick={() =>
                            handleClick(
                              this.props.location.pathname,
                              "/manage-fw"
                            )
                          }
                        >
                          Manage FW
                        </Link>
                      </li>
                    )}
                    {!isRoot && manageNodeCritical && (
                      <li>
                        <Link
                          className=" "
                          to="/custom-actions"
                          onClick={() =>
                            handleClick(
                              this.props.location.pathname,
                              "/custom-actions"
                            )
                          }
                        >
                          Custom Action
                        </Link>
                      </li>
                    )}
                  </ul>
                </li>
              )}
            {!isRoot && (manageUserGroups || isManager) && (
              <li
                id="managusergroup-side-button"
                className={`${
                  manageusergroup.includes(path.slice(1)) ? "mm-active " : ""
                }`}
              >
                <div className="sidebar-tag mt-3">User And Group</div>
                <Link
                  className="has-arrow ai-icon sidebar-navarrow"
                  to="#"
                  aria-expanded="false"
                >
                  <i className="flaticon-381-user-9"></i>
                  <span className="nav-text sidebar-navtext">
                    Manage User Group
                  </span>
                </Link>
                <ul aria-expanded="false">
                  <li>
                    <Link
                      className=" "
                      to="/manage-usergroup"
                      onClick={() =>
                        handleClick(
                          this.props.location.pathname,
                          "/manage-usergroup"
                        )
                      }
                    >
                      Manage User Group
                    </Link>
                  </li>
                  {manageUserGroups && (
                    <li>
                      <Link
                        className=" "
                        to="/user-list"
                        onClick={() =>
                          handleClick(
                            this.props.location.pathname,
                            "/user-list"
                          )
                        }
                      >
                        Manage User List
                      </Link>
                    </li>
                  )}
                </ul>
              </li>
            )}
            {isRoot && (
              <li
                id="manageorg-side-button"
                className={`${
                  manageorg.includes(path.slice(1)) ? "mm-active " : ""
                }`}
              >
                <Link
                  className="has-arrow ai-icon sidebar-navarrow"
                  to="#"
                  aria-expanded="false"
                >
                  <i className="flaticon-381-briefcase"></i>
                  <span className="nav-text sidebar-navtext">
                    Manage Organization
                  </span>
                </Link>
                <ul aria-expanded="false">
                  <li>
                    <Link
                      className=" "
                      to="/manage-org"
                      onClick={() =>
                        handleClick(this.props.location.pathname, "/manage-org")
                      }
                    >
                      Manage Organization
                    </Link>
                  </li>
                </ul>
              </li>
            )}

            {!isRoot && manageNodeCritical && (
              <li
                id="task-side-button"
                className={`${
                  managetask.includes(path.slice(1)) ? "mm-active" : ""
                }`}
              >
                <div className="sidebar-tag mt-2">Task</div>
                <Link
                  to="manage-task"
                  className=" has-arrow ai-icon sidebar-navarrow"
                  aria-expanded="false"
                >
                  <i className="flaticon-381-notepad "></i>
                  <span className="nav-text sidebar-navtext">Manage Task</span>
                </Link>
                <ul aria-expanded="false">
                  <li>
                    <Link
                      className=" "
                      to="/manage-task"
                      onClick={() =>
                        handleClick(
                          this.props.location.pathname,
                          "/manage-task"
                        )
                      }
                    >
                      Manage Task
                    </Link>
                  </li>
                </ul>
              </li>
            )}

            {manageProvision && !isRoot && (
              <div className="sidebar-tag mt-2">Setting</div>
            )}
            {manageProvision && !isRoot && (
              <li
                id="setting-side-button"
                className={`${
                  setprovision.includes(path.slice(1)) ? "mm-active " : ""
                }`}
              >
                <Link
                  className="has-arrow ai-icon sidebar-navarrow"
                  to="#"
                  aria-expanded="false"
                >
                  <i className="flaticon-381-network"></i>
                  <span className="nav-text sidebar-navtext">
                    Setting provision
                  </span>
                </Link>
                <ul aria-expanded="false">
                  <li>
                    <Link
                      className=" "
                      to="/setting-provision"
                      onClick={() =>
                        handleClick(
                          this.props.location.pathname,
                          "/setting-provision"
                        )
                      }
                    >
                      Setting provision
                    </Link>
                  </li>
                </ul>
              </li>
            )}
            {!isRoot && (
              <li
                id="setting-side-button"
                className={`${
                  notificationsetting.includes(path.slice(1))
                    ? "mm-active "
                    : ""
                }`}
              >
                <Link
                  className="has-arrow ai-icon sidebar-navarrow"
                  to="#"
                  aria-expanded="false"
                >
                  <i className="flaticon-381-notification"></i>
                  <span className="nav-text sidebar-navtext">
                    Notification Setting
                  </span>
                </Link>
                <ul aria-expanded="false">
                  <li>
                    <Link
                      className=" "
                      to="/notification-setting"
                      onClick={() =>
                        handleClick(
                          this.props.location.pathname,
                          "/notification-setting"
                        )
                      }
                    >
                      Notification Setting
                    </Link>
                  </li>
                </ul>
              </li>
            )}
          </MM>
        </PerfectScrollbar>
      </div>
    );
  }
}

export default SideBar;
